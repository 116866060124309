.teaser--halb {
  .teaser {
    width: 100%;
    height: calc(100vh / 1.2);
    max-height: 400px;
    min-height: 300px;
    position: relative;
    overflow: hidden;

    .teaser--bg {
      width: 100%;
      height: 250%;
      position: absolute;
      opacity: 0;
      transition: opacity 0.6s;
      img {
        height: auto;
      }
    }

    .active {
      opacity: 1;
    }

    .teaser--shadow {
      position: absolute;
      bottom: -10px;
      width: 100vw;
      height: 0px;
      box-shadow: 0px -10px 10px #000000;
      transform: rotate(-0.5deg);
      z-index: 999;
    }

    article {
      position: absolute;
      top: 30%;
      left: 0%;
      width: 100vw;
      padding-left: 180px;
      padding-right: 180px;

      h1 {
        font-size: 90px;
        color: #ffffff;
        font-weight: 600;
        text-align: left;
        border-bottom: 0px !important;
        font-family: "Roboto Condensed";
      }

      p {
        font-size: 25px;
        text-shadow: rgba(0, 0, 0, 0.9) 1px 1px 10px;
        color: #ffffff;
        opacity: 0;
        text-align: left;
        font-family: "Roboto";
      }

      .embed-responsive {
        box-shadow: 0px 0px 15px #000;
        background: theme-color('primary');
        border-radius: 15px;
      }

      .fade-in {
        opacity: 0;
      }
    }

    .fade-rtl, .fade-ltr {
      transform: matrix(1, 0, 0, 1, 0, 0);
      transition: all, 0s, 0s;
      transition-duration: 3s, 3s;
      transition-property: transform, opacity;
      transition-timing-function: ease-in-out, linear;
      will-change: opacity, transform;
      opacity: 0;
    }

    .fade-rtl {
      transform: translate(50px);
    }

    .fade-ltr {
      transform: translate(-50px);
    }

    #conversionbuttonindex2 {
      position: absolute;
      right: 20%;
      bottom: 35%;
      opacity: 0;
      font-size: 35px;
      box-shadow: 0px 0px 15px #333;
      padding: 10px 20px 10px 20px;
      transition: all 0.5s ease;
    }
  }
}

@include media-breakpoint-down(sm) {
  .teaser--halb {
    .teaser {
      max-height: 110px;
      min-height: 240px;
    }
  }
  .clock {
    i {
      svg, img {
        height: 30px !important;
        transform: translate(10px, -9px) !important;
      }
    }
  }
}

.has-open, .has-closed {

  position: absolute;
  z-index: 999;
  height: auto;
  transform: translate(-50%, -80px);
  left: 50%;
  background: #ffffff;
  width: 580px;
  display: flex;
  height: 80px;
  font-family: 'Roboto Condensed';
  //transition: transform 0.3s ease-in-out 2s;

  .clock {
    i {
      font-size: 60px;
      color: #cdc0b0;
      svg, img {
        height: 60px;
        transform: translate(10px, -15px);
      }
    }
    width: 85px;
    height: 60px;
    margin-top: 10px;
    border-right: 1px solid #cdc0b0;
    margin-right: 15px;
  }

  .text {
    font-size: 50px;
    font-weight: bold;
    color: #301610;
    margin-top: 5px;
  }

  .text-closed {
    font-size: 50px;
    font-weight: bold;
    color: #301610;
    margin-top: 5px;
  }

  .zeit {
    position: relative;
    width: 165px;
    margin-left: 20px;
    margin-top: 10px;
    font-weight: bold;
    font-size: 35px;
    color: #cdc0b0;
  }
}

.teaser--shadow {
  width: 100%;
  height: 10px;
  background: transparent;
  transform: translateY(0px);
  z-index: 1001;
  position: relative;
  box-shadow: 0px -10px 10px rgba(0,0,0,0.5)!important;
}

.teaser--main {
  overflow: hidden;
  position: relative;
}

.has-closed {
  width: 450px;
}