.content--shadow-footer {
  position: absolute;
  bottom: 0px;
  width: 100vw;
  transform: translate(0px, 0%);
  picture {
    position: relative;
    width: 100%;
    left: 0px;
    top: 0px;
    img {
      width: 100%;
      position: relative;
      transform: translateX(-50%);
      filter: brightness(1) !important;
      left: 50%;
      z-index: 999;
    }
  }
}


footer {
  z-index: 60;
  background: transparent;
  padding: 0px;
  margin: 0px !important;
  position: relative;
  width: 100%;
  min-height: 50px;
  .logo-footer {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    background: #fff;
    border-radius: 15px;
    padding: 15px;
    top: 0px;
    box-shadow: 0px 10px 15px #333;
    img {
      width: 225px;
    }
  }
  @include media-breakpoint-down(lg) {
    .logo-footer {
      img {
        width: 100px;
      }
    }
  }
  .texture {
    width: 100%;
    padding: 0px;
    position: relative;
    overflow: hidden;
    min-height: 120px;
    .stoff {
      position: absolute;
      background: url('../../../media/stoff-XL@1x/stoff_texture.jpg');
      background-size: cover;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        filter: brightness(0.7);
      }
    }

    .grid-container {
      padding-top: 100px;
      h1, h2, p {
        color: #ffffff !important;
        text-shadow: -1px -1px 0px #000000;
      }
    }
  }

  .bg-white {
    width: 100%;
    position: relative;
    text-align: center;
    padding-bottom: 1px;
    padding-top: 10px;
    .grid-container {
      .container-fluid {
        padding-top: 10px;
      }
    }

    .links {
      font-size: 40px;
      font-family: "Roboto Condensed";
      font-weight: 600;
      color: #d9b37d;
      a {
        color: #d9b37d;
      }
    }
    @include media-breakpoint-down(lg) {
      .links {
        font-size: 20px;
        padding-bottom: 30px;
      }
    }
    .copyright {
      font-family: "Roboto Condensed";
      font-size: 15px;
      font-weight: 400;
      color: #d9b37d;
    }
  }
  .content--shadow-footer, .content--shadow {
    position: absolute;
    top: 0px;
    width: 100%;
    picture {
      position: absolute;
      width: 100%;
      left: 0px;
      top: 0px;
      img {
        width: 100%;
        position: absolute;
        transform: translateX(-50%);
        filter: brightness(1) !important;
        left: 50%;
        z-index: 999;
      }
    }
  }
}