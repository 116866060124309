body {
  .navbar {
    transition: background-color 0.8s;
  }

  h2 {
    transition: color 0.8s;
  }
  .content {
    .grid-container {
      //transition: all 0.8s;
    }
  }
}

.clicked {
  .navbar {
    background-color: #6b5f4e;
  }
  h2 {
    color: #ffffff;
  }
  .content {
    .grid-container {
      background: #6b5f4e;
      color: #ffffff;
    }
  }
}