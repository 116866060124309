/* roboto-condensed-300 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/roboto-condensed-v25-latin-300.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-condensed-v25-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-condensed-v25-latin-300.woff2") format("woff2"), url("../fonts/roboto-condensed-v25-latin-300.woff") format("woff"), url("../fonts/roboto-condensed-v25-latin-300.ttf") format("truetype"), url("../fonts/roboto-condensed-v25-latin-300.svg#RobotoCondensed") format("svg");
  /* Legacy iOS */ }

/* roboto-condensed-regular - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto-condensed-v25-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-condensed-v25-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-condensed-v25-latin-regular.woff2") format("woff2"), url("../fonts/roboto-condensed-v25-latin-regular.woff") format("woff"), url("../fonts/roboto-condensed-v25-latin-regular.ttf") format("truetype"), url("../fonts/roboto-condensed-v25-latin-regular.svg#RobotoCondensed") format("svg");
  /* Legacy iOS */ }

/* roboto-condensed-700 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/roboto-condensed-v25-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-condensed-v25-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-condensed-v25-latin-700.woff2") format("woff2"), url("../fonts/roboto-condensed-v25-latin-700.woff") format("woff"), url("../fonts/roboto-condensed-v25-latin-700.ttf") format("truetype"), url("../fonts/roboto-condensed-v25-latin-700.svg#RobotoCondensed") format("svg");
  /* Legacy iOS */ }

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --secondary: #ffffff;
  --primary: #878787;
  --primary-dark: #301610;
  --primary-light: #ffffff;
  --primary-light-hover: #60a900;
  --btn-primary-text-hover: #fff;
  --btn-primary-text: #212529;
  --btn-primary-bg: #ffa401;
  --body-bg-hover: #e0e0e0;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Roboto Condensed";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #666666;
  text-align: left;
  background-color: #ffffff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #ffffff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: white; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: white; }

.table-hover .table-secondary:hover {
  background-color: #f2f2f2; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #f2f2f2; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #dddddd; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #c1c1c1; }

.table-hover .table-primary:hover {
  background-color: #d0d0d0; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #d0d0d0; }

.table-primary-dark,
.table-primary-dark > th,
.table-primary-dark > td {
  background-color: #c5bebc; }

.table-primary-dark th,
.table-primary-dark td,
.table-primary-dark thead th,
.table-primary-dark tbody + tbody {
  border-color: #938683; }

.table-hover .table-primary-dark:hover {
  background-color: #b9b1ae; }
  .table-hover .table-primary-dark:hover > td,
  .table-hover .table-primary-dark:hover > th {
    background-color: #b9b1ae; }

.table-primary-light,
.table-primary-light > th,
.table-primary-light > td {
  background-color: white; }

.table-primary-light th,
.table-primary-light td,
.table-primary-light thead th,
.table-primary-light tbody + tbody {
  border-color: white; }

.table-hover .table-primary-light:hover {
  background-color: #f2f2f2; }
  .table-hover .table-primary-light:hover > td,
  .table-hover .table-primary-light:hover > th {
    background-color: #f2f2f2; }

.table-primary-light-hover,
.table-primary-light-hover > th,
.table-primary-light-hover > td {
  background-color: #d2e7b8; }

.table-primary-light-hover th,
.table-primary-light-hover td,
.table-primary-light-hover thead th,
.table-primary-light-hover tbody + tbody {
  border-color: #acd27a; }

.table-hover .table-primary-light-hover:hover {
  background-color: #c6e1a5; }
  .table-hover .table-primary-light-hover:hover > td,
  .table-hover .table-primary-light-hover:hover > th {
    background-color: #c6e1a5; }

.table-btn-primary-text-hover,
.table-btn-primary-text-hover > th,
.table-btn-primary-text-hover > td {
  background-color: white; }

.table-btn-primary-text-hover th,
.table-btn-primary-text-hover td,
.table-btn-primary-text-hover thead th,
.table-btn-primary-text-hover tbody + tbody {
  border-color: white; }

.table-hover .table-btn-primary-text-hover:hover {
  background-color: #f2f2f2; }
  .table-hover .table-btn-primary-text-hover:hover > td,
  .table-hover .table-btn-primary-text-hover:hover > th {
    background-color: #f2f2f2; }

.table-btn-primary-text,
.table-btn-primary-text > th,
.table-btn-primary-text > td {
  background-color: #c1c2c3; }

.table-btn-primary-text th,
.table-btn-primary-text td,
.table-btn-primary-text thead th,
.table-btn-primary-text tbody + tbody {
  border-color: #8c8e90; }

.table-hover .table-btn-primary-text:hover {
  background-color: #b4b5b6; }
  .table-hover .table-btn-primary-text:hover > td,
  .table-hover .table-btn-primary-text:hover > th {
    background-color: #b4b5b6; }

.table-btn-primary-bg,
.table-btn-primary-bg > th,
.table-btn-primary-bg > td {
  background-color: #ffe6b8; }

.table-btn-primary-bg th,
.table-btn-primary-bg td,
.table-btn-primary-bg thead th,
.table-btn-primary-bg tbody + tbody {
  border-color: #ffd07b; }

.table-hover .table-btn-primary-bg:hover {
  background-color: #ffdd9f; }
  .table-hover .table-btn-primary-bg:hover > td,
  .table-hover .table-btn-primary-bg:hover > th {
    background-color: #ffdd9f; }

.table-body-bg-hover,
.table-body-bg-hover > th,
.table-body-bg-hover > td {
  background-color: #f6f6f6; }

.table-body-bg-hover th,
.table-body-bg-hover td,
.table-body-bg-hover thead th,
.table-body-bg-hover tbody + tbody {
  border-color: #efefef; }

.table-hover .table-body-bg-hover:hover {
  background-color: #e9e9e9; }
  .table-hover .table-body-bg-hover:hover > td,
  .table-hover .table-body-bg-hover:hover > th {
    background-color: #e9e9e9; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #666666;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #666666;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-secondary {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff; }
  .btn-secondary:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-primary {
  color: #fff;
  background-color: #878787;
  border-color: #878787; }
  .btn-primary:hover {
    color: #fff;
    background-color: #747474;
    border-color: #6e6e6e; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #878787;
    border-color: #878787; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #6e6e6e;
    border-color: #676767; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5); }

.btn-primary-dark {
  color: #fff;
  background-color: #301610;
  border-color: #301610; }
  .btn-primary-dark:hover {
    color: #fff;
    background-color: #130906;
    border-color: #0a0403; }
  .btn-primary-dark:focus, .btn-primary-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(79, 57, 52, 0.5); }
  .btn-primary-dark.disabled, .btn-primary-dark:disabled {
    color: #fff;
    background-color: #301610;
    border-color: #301610; }
  .btn-primary-dark:not(:disabled):not(.disabled):active, .btn-primary-dark:not(:disabled):not(.disabled).active,
  .show > .btn-primary-dark.dropdown-toggle {
    color: #fff;
    background-color: #0a0403;
    border-color: black; }
    .btn-primary-dark:not(:disabled):not(.disabled):active:focus, .btn-primary-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(79, 57, 52, 0.5); }

.btn-primary-light {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff; }
  .btn-primary-light:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-primary-light:focus, .btn-primary-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-primary-light.disabled, .btn-primary-light:disabled {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-primary-light:not(:disabled):not(.disabled):active, .btn-primary-light:not(:disabled):not(.disabled).active,
  .show > .btn-primary-light.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-primary-light:not(:disabled):not(.disabled):active:focus, .btn-primary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-primary-light-hover {
  color: #fff;
  background-color: #60a900;
  border-color: #60a900; }
  .btn-primary-light-hover:hover {
    color: #fff;
    background-color: #4a8300;
    border-color: #437600; }
  .btn-primary-light-hover:focus, .btn-primary-light-hover.focus {
    box-shadow: 0 0 0 0.2rem rgba(120, 182, 38, 0.5); }
  .btn-primary-light-hover.disabled, .btn-primary-light-hover:disabled {
    color: #fff;
    background-color: #60a900;
    border-color: #60a900; }
  .btn-primary-light-hover:not(:disabled):not(.disabled):active, .btn-primary-light-hover:not(:disabled):not(.disabled).active,
  .show > .btn-primary-light-hover.dropdown-toggle {
    color: #fff;
    background-color: #437600;
    border-color: #3c6900; }
    .btn-primary-light-hover:not(:disabled):not(.disabled):active:focus, .btn-primary-light-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-light-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(120, 182, 38, 0.5); }

.btn-btn-primary-text-hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff; }
  .btn-btn-primary-text-hover:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-btn-primary-text-hover:focus, .btn-btn-primary-text-hover.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-btn-primary-text-hover.disabled, .btn-btn-primary-text-hover:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-btn-primary-text-hover:not(:disabled):not(.disabled):active, .btn-btn-primary-text-hover:not(:disabled):not(.disabled).active,
  .show > .btn-btn-primary-text-hover.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-btn-primary-text-hover:not(:disabled):not(.disabled):active:focus, .btn-btn-primary-text-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-btn-primary-text-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-btn-primary-text {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }
  .btn-btn-primary-text:hover {
    color: #fff;
    background-color: #101214;
    border-color: #0a0c0d; }
  .btn-btn-primary-text:focus, .btn-btn-primary-text.focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }
  .btn-btn-primary-text.disabled, .btn-btn-primary-text:disabled {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .btn-btn-primary-text:not(:disabled):not(.disabled):active, .btn-btn-primary-text:not(:disabled):not(.disabled).active,
  .show > .btn-btn-primary-text.dropdown-toggle {
    color: #fff;
    background-color: #0a0c0d;
    border-color: #050506; }
    .btn-btn-primary-text:not(:disabled):not(.disabled):active:focus, .btn-btn-primary-text:not(:disabled):not(.disabled).active:focus,
    .show > .btn-btn-primary-text.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }

.btn-btn-primary-bg {
  color: #212529;
  background-color: #ffa401;
  border-color: #ffa401; }
  .btn-btn-primary-bg:hover {
    color: #fff;
    background-color: #da8c00;
    border-color: #cd8400; }
  .btn-btn-primary-bg:focus, .btn-btn-primary-bg.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 145, 7, 0.5); }
  .btn-btn-primary-bg.disabled, .btn-btn-primary-bg:disabled {
    color: #212529;
    background-color: #ffa401;
    border-color: #ffa401; }
  .btn-btn-primary-bg:not(:disabled):not(.disabled):active, .btn-btn-primary-bg:not(:disabled):not(.disabled).active,
  .show > .btn-btn-primary-bg.dropdown-toggle {
    color: #fff;
    background-color: #cd8400;
    border-color: #c07b00; }
    .btn-btn-primary-bg:not(:disabled):not(.disabled):active:focus, .btn-btn-primary-bg:not(:disabled):not(.disabled).active:focus,
    .show > .btn-btn-primary-bg.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 145, 7, 0.5); }

.btn-body-bg-hover {
  color: #212529;
  background-color: #e0e0e0;
  border-color: #e0e0e0; }
  .btn-body-bg-hover:hover {
    color: #212529;
    background-color: #cdcdcd;
    border-color: #c7c7c7; }
  .btn-body-bg-hover:focus, .btn-body-bg-hover.focus {
    box-shadow: 0 0 0 0.2rem rgba(195, 196, 197, 0.5); }
  .btn-body-bg-hover.disabled, .btn-body-bg-hover:disabled {
    color: #212529;
    background-color: #e0e0e0;
    border-color: #e0e0e0; }
  .btn-body-bg-hover:not(:disabled):not(.disabled):active, .btn-body-bg-hover:not(:disabled):not(.disabled).active,
  .show > .btn-body-bg-hover.dropdown-toggle {
    color: #212529;
    background-color: #c7c7c7;
    border-color: silver; }
    .btn-body-bg-hover:not(:disabled):not(.disabled):active:focus, .btn-body-bg-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-body-bg-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(195, 196, 197, 0.5); }

.btn-outline-secondary {
  color: #ffffff;
  border-color: #ffffff; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #ffffff;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-primary {
  color: #878787;
  border-color: #878787; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #878787;
    border-color: #878787; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(135, 135, 135, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #878787;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #878787;
    border-color: #878787; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(135, 135, 135, 0.5); }

.btn-outline-primary-dark {
  color: #301610;
  border-color: #301610; }
  .btn-outline-primary-dark:hover {
    color: #fff;
    background-color: #301610;
    border-color: #301610; }
  .btn-outline-primary-dark:focus, .btn-outline-primary-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(48, 22, 16, 0.5); }
  .btn-outline-primary-dark.disabled, .btn-outline-primary-dark:disabled {
    color: #301610;
    background-color: transparent; }
  .btn-outline-primary-dark:not(:disabled):not(.disabled):active, .btn-outline-primary-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-dark.dropdown-toggle {
    color: #fff;
    background-color: #301610;
    border-color: #301610; }
    .btn-outline-primary-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(48, 22, 16, 0.5); }

.btn-outline-primary-light {
  color: #ffffff;
  border-color: #ffffff; }
  .btn-outline-primary-light:hover {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-outline-primary-light:focus, .btn-outline-primary-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-primary-light.disabled, .btn-outline-primary-light:disabled {
    color: #ffffff;
    background-color: transparent; }
  .btn-outline-primary-light:not(:disabled):not(.disabled):active, .btn-outline-primary-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-light.dropdown-toggle {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
    .btn-outline-primary-light:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-primary-light-hover {
  color: #60a900;
  border-color: #60a900; }
  .btn-outline-primary-light-hover:hover {
    color: #fff;
    background-color: #60a900;
    border-color: #60a900; }
  .btn-outline-primary-light-hover:focus, .btn-outline-primary-light-hover.focus {
    box-shadow: 0 0 0 0.2rem rgba(96, 169, 0, 0.5); }
  .btn-outline-primary-light-hover.disabled, .btn-outline-primary-light-hover:disabled {
    color: #60a900;
    background-color: transparent; }
  .btn-outline-primary-light-hover:not(:disabled):not(.disabled):active, .btn-outline-primary-light-hover:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-light-hover.dropdown-toggle {
    color: #fff;
    background-color: #60a900;
    border-color: #60a900; }
    .btn-outline-primary-light-hover:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-light-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-light-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(96, 169, 0, 0.5); }

.btn-outline-btn-primary-text-hover {
  color: #fff;
  border-color: #fff; }
  .btn-outline-btn-primary-text-hover:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-btn-primary-text-hover:focus, .btn-outline-btn-primary-text-hover.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-btn-primary-text-hover.disabled, .btn-outline-btn-primary-text-hover:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-btn-primary-text-hover:not(:disabled):not(.disabled):active, .btn-outline-btn-primary-text-hover:not(:disabled):not(.disabled).active,
  .show > .btn-outline-btn-primary-text-hover.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-btn-primary-text-hover:not(:disabled):not(.disabled):active:focus, .btn-outline-btn-primary-text-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-btn-primary-text-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-btn-primary-text {
  color: #212529;
  border-color: #212529; }
  .btn-outline-btn-primary-text:hover {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .btn-outline-btn-primary-text:focus, .btn-outline-btn-primary-text.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }
  .btn-outline-btn-primary-text.disabled, .btn-outline-btn-primary-text:disabled {
    color: #212529;
    background-color: transparent; }
  .btn-outline-btn-primary-text:not(:disabled):not(.disabled):active, .btn-outline-btn-primary-text:not(:disabled):not(.disabled).active,
  .show > .btn-outline-btn-primary-text.dropdown-toggle {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
    .btn-outline-btn-primary-text:not(:disabled):not(.disabled):active:focus, .btn-outline-btn-primary-text:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-btn-primary-text.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }

.btn-outline-btn-primary-bg {
  color: #ffa401;
  border-color: #ffa401; }
  .btn-outline-btn-primary-bg:hover {
    color: #212529;
    background-color: #ffa401;
    border-color: #ffa401; }
  .btn-outline-btn-primary-bg:focus, .btn-outline-btn-primary-bg.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 164, 1, 0.5); }
  .btn-outline-btn-primary-bg.disabled, .btn-outline-btn-primary-bg:disabled {
    color: #ffa401;
    background-color: transparent; }
  .btn-outline-btn-primary-bg:not(:disabled):not(.disabled):active, .btn-outline-btn-primary-bg:not(:disabled):not(.disabled).active,
  .show > .btn-outline-btn-primary-bg.dropdown-toggle {
    color: #212529;
    background-color: #ffa401;
    border-color: #ffa401; }
    .btn-outline-btn-primary-bg:not(:disabled):not(.disabled):active:focus, .btn-outline-btn-primary-bg:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-btn-primary-bg.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 164, 1, 0.5); }

.btn-outline-body-bg-hover {
  color: #e0e0e0;
  border-color: #e0e0e0; }
  .btn-outline-body-bg-hover:hover {
    color: #212529;
    background-color: #e0e0e0;
    border-color: #e0e0e0; }
  .btn-outline-body-bg-hover:focus, .btn-outline-body-bg-hover.focus {
    box-shadow: 0 0 0 0.2rem rgba(224, 224, 224, 0.5); }
  .btn-outline-body-bg-hover.disabled, .btn-outline-body-bg-hover:disabled {
    color: #e0e0e0;
    background-color: transparent; }
  .btn-outline-body-bg-hover:not(:disabled):not(.disabled):active, .btn-outline-body-bg-hover:not(:disabled):not(.disabled).active,
  .show > .btn-outline-body-bg-hover.dropdown-toggle {
    color: #212529;
    background-color: #e0e0e0;
    border-color: #e0e0e0; }
    .btn-outline-body-bg-hover:not(:disabled):not(.disabled):active:focus, .btn-outline-body-bg-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-body-bg-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(224, 224, 224, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #666666;
  text-align: left;
  list-style: none;
  background-color: rgba(255, 255, 255, 0.7);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropdown-menu-left {
  right: auto;
  left: 0; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #ffffff;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:first-child {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px); }
  .dropdown-item:last-child {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px); }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: none; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #ffffff; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #ffffff;
    border-color: #dee2e6 #dee2e6 #ffffff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  color: inherit;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion .card {
  overflow: hidden; }
  .accordion .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion .card .card-header {
    margin-bottom: -1px; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #666666;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-secondary {
  color: #858585;
  background-color: white; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-primary {
  color: #464646;
  background-color: #dddddd; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #464646;
    background-color: #d0d0d0; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #464646;
    border-color: #464646; }

.list-group-item-primary-dark {
  color: #190b08;
  background-color: #c5bebc; }
  .list-group-item-primary-dark.list-group-item-action:hover, .list-group-item-primary-dark.list-group-item-action:focus {
    color: #190b08;
    background-color: #b9b1ae; }
  .list-group-item-primary-dark.list-group-item-action.active {
    color: #fff;
    background-color: #190b08;
    border-color: #190b08; }

.list-group-item-primary-light {
  color: #858585;
  background-color: white; }
  .list-group-item-primary-light.list-group-item-action:hover, .list-group-item-primary-light.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-primary-light.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-primary-light-hover {
  color: #325800;
  background-color: #d2e7b8; }
  .list-group-item-primary-light-hover.list-group-item-action:hover, .list-group-item-primary-light-hover.list-group-item-action:focus {
    color: #325800;
    background-color: #c6e1a5; }
  .list-group-item-primary-light-hover.list-group-item-action.active {
    color: #fff;
    background-color: #325800;
    border-color: #325800; }

.list-group-item-btn-primary-text-hover {
  color: #858585;
  background-color: white; }
  .list-group-item-btn-primary-text-hover.list-group-item-action:hover, .list-group-item-btn-primary-text-hover.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-btn-primary-text-hover.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-btn-primary-text {
  color: #111315;
  background-color: #c1c2c3; }
  .list-group-item-btn-primary-text.list-group-item-action:hover, .list-group-item-btn-primary-text.list-group-item-action:focus {
    color: #111315;
    background-color: #b4b5b6; }
  .list-group-item-btn-primary-text.list-group-item-action.active {
    color: #fff;
    background-color: #111315;
    border-color: #111315; }

.list-group-item-btn-primary-bg {
  color: #855501;
  background-color: #ffe6b8; }
  .list-group-item-btn-primary-bg.list-group-item-action:hover, .list-group-item-btn-primary-bg.list-group-item-action:focus {
    color: #855501;
    background-color: #ffdd9f; }
  .list-group-item-btn-primary-bg.list-group-item-action.active {
    color: #fff;
    background-color: #855501;
    border-color: #855501; }

.list-group-item-body-bg-hover {
  color: #747474;
  background-color: #f6f6f6; }
  .list-group-item-body-bg-hover.list-group-item-action:hover, .list-group-item-body-bg-hover.list-group-item-action:focus {
    color: #747474;
    background-color: #e9e9e9; }
  .list-group-item-body-bg-hover.list-group-item-action.active {
    color: #fff;
    background-color: #747474;
    border-color: #747474; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - (0.5rem * 2));
    content: ""; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
    .modal-dialog-centered::before {
      height: calc(100vh - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-secondary {
  background-color: #ffffff !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #e6e6e6 !important; }

.bg-primary {
  background-color: #878787 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #6e6e6e !important; }

.bg-primary-dark {
  background-color: #301610 !important; }

a.bg-primary-dark:hover, a.bg-primary-dark:focus,
button.bg-primary-dark:hover,
button.bg-primary-dark:focus {
  background-color: #0a0403 !important; }

.bg-primary-light {
  background-color: #ffffff !important; }

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #e6e6e6 !important; }

.bg-primary-light-hover {
  background-color: #60a900 !important; }

a.bg-primary-light-hover:hover, a.bg-primary-light-hover:focus,
button.bg-primary-light-hover:hover,
button.bg-primary-light-hover:focus {
  background-color: #437600 !important; }

.bg-btn-primary-text-hover {
  background-color: #fff !important; }

a.bg-btn-primary-text-hover:hover, a.bg-btn-primary-text-hover:focus,
button.bg-btn-primary-text-hover:hover,
button.bg-btn-primary-text-hover:focus {
  background-color: #e6e6e6 !important; }

.bg-btn-primary-text {
  background-color: #212529 !important; }

a.bg-btn-primary-text:hover, a.bg-btn-primary-text:focus,
button.bg-btn-primary-text:hover,
button.bg-btn-primary-text:focus {
  background-color: #0a0c0d !important; }

.bg-btn-primary-bg {
  background-color: #ffa401 !important; }

a.bg-btn-primary-bg:hover, a.bg-btn-primary-bg:focus,
button.bg-btn-primary-bg:hover,
button.bg-btn-primary-bg:focus {
  background-color: #cd8400 !important; }

.bg-body-bg-hover {
  background-color: #e0e0e0 !important; }

a.bg-body-bg-hover:hover, a.bg-body-bg-hover:focus,
button.bg-body-bg-hover:hover,
button.bg-body-bg-hover:focus {
  background-color: #c7c7c7 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-secondary {
  border-color: #ffffff !important; }

.border-primary {
  border-color: #878787 !important; }

.border-primary-dark {
  border-color: #301610 !important; }

.border-primary-light {
  border-color: #ffffff !important; }

.border-primary-light-hover {
  border-color: #60a900 !important; }

.border-btn-primary-text-hover {
  border-color: #fff !important; }

.border-btn-primary-text {
  border-color: #212529 !important; }

.border-btn-primary-bg {
  border-color: #ffa401 !important; }

.border-body-bg-hover {
  border-color: #e0e0e0 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-3by4::before {
  padding-top: 133.33333%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-secondary {
  color: #ffffff !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #d9d9d9 !important; }

.text-primary {
  color: #878787 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #616161 !important; }

.text-primary-dark {
  color: #301610 !important; }

a.text-primary-dark:hover, a.text-primary-dark:focus {
  color: black !important; }

.text-primary-light {
  color: #ffffff !important; }

a.text-primary-light:hover, a.text-primary-light:focus {
  color: #d9d9d9 !important; }

.text-primary-light-hover {
  color: #60a900 !important; }

a.text-primary-light-hover:hover, a.text-primary-light-hover:focus {
  color: #355d00 !important; }

.text-btn-primary-text-hover {
  color: #fff !important; }

a.text-btn-primary-text-hover:hover, a.text-btn-primary-text-hover:focus {
  color: #d9d9d9 !important; }

.text-btn-primary-text {
  color: #212529 !important; }

a.text-btn-primary-text:hover, a.text-btn-primary-text:focus {
  color: black !important; }

.text-btn-primary-bg {
  color: #ffa401 !important; }

a.text-btn-primary-bg:hover, a.text-btn-primary-bg:focus {
  color: #b47300 !important; }

.text-body-bg-hover {
  color: #e0e0e0 !important; }

a.text-body-bg-hover:hover, a.text-body-bg-hover:focus {
  color: #bababa !important; }

.text-body {
  color: #666666 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

header .header--shadow {
  position: absolute;
  top: 126px; }

.navbar {
  padding: 15px;
  width: 100%;
  height: 150px;
  background-color: #d9b37d;
  box-shadow: 0px -5px 20px 10px #000;
  z-index: 99999; }
  .navbar .navbar-collapse {
    flex-grow: unset !important; }
  .navbar .navbar-toggler-icon {
    background: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important; }
  .navbar .text-light i {
    color: #ffffff !important; }
  .navbar .btn {
    margin-left: 10px;
    background: #878787;
    color: #ccc;
    border: 0px; }
  .navbar .btn-secondary:hover {
    background: #301610; }
  .navbar .logo {
    height: 97px;
    position: absolute;
    top: 15px;
    left: 20px; }
  .navbar .navbar-nav {
    font-size: 35px;
    font-family: "Roboto Condensed";
    text-transform: uppercase;
    width: inital;
    padding: 0px 100px;
    height: 150px; }
    .navbar .navbar-nav .nav-item {
      display: flex;
      justify-content: center;
      position: relative;
      padding-right: 50px; }
      .navbar .navbar-nav .nav-item a {
        color: #ffffff;
        display: flex;
        align-items: center;
        text-shadow: -1px -1px 0px #a38254;
        font-family: "Roboto Condensed";
        font-weight: 400;
        font-size: 32px;
        transition: opacity 0.3s; }
      .navbar .navbar-nav .nav-item > a:hover {
        opacity: 0.5;
        color: #fff !important; }
      .navbar .navbar-nav .nav-item .dropdown-toggle::after {
        content: unset; }
    .navbar .navbar-nav .dropdown-toggle::after {
      content: unset; }
    .navbar .navbar-nav .active {
      color: white !important; }
      .navbar .navbar-nav .active a {
        color: white !important; }
    .navbar .navbar-nav .submenue {
      display: flex;
      flex-direction: column;
      font-size: 20px; }
      .navbar .navbar-nav .submenue .dropdown-item {
        background: none;
        font-size: 30px; }
  .navbar .dropdown-menu {
    left: unset !important;
    top: 0px;
    display: none;
    flex-direction: column;
    border: 0px;
    box-shadow: 0px 0px 5px #999;
    width: 100%;
    border-radius: 0px;
    background: rgba(255, 255, 255, 0.9); }
    .navbar .dropdown-menu .dropdown-item {
      font-size: 30px;
      text-align: center;
      text-transform: none;
      font-weight: 600;
      justify-content: center;
      text-shadow: 1px 1px #ffffff !important;
      color: #333 !important; }
    .navbar .dropdown-menu .submenue-second {
      font-size: 20px;
      padding: 0px 80px; }
  .navbar .show {
    display: flex; }
    .navbar .show > .nav-link {
      color: #fff !important; }
  .navbar .navbar-toggler {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 999999; }

.navbar-dark .navbar-toggler {
  color: #ffffff;
  border-color: #ffffff; }

.teaser {
  width: 100%;
  height: calc(100vh - 130px);
  min-height: 500px;
  position: relative;
  overflow: hidden;
  margin-top: -18px; }
  .teaser .embed-responsive {
    object-fit: cover;
    height: calc(100vh); }
    .teaser .embed-responsive video {
      object-fit: cover; }
  .teaser .teaser--bg {
    width: 100%;
    height: calc(100vh - 80px);
    position: absolute;
    opacity: 0;
    transition: opacity 0.6s; }
    .teaser .teaser--bg img {
      object-fit: cover;
      width: 100%;
      height: 100%; }
  .teaser .active {
    opacity: 1; }
  .teaser .teaser--shadow {
    position: absolute;
    bottom: -10px;
    width: 100vw;
    height: 0px;
    box-shadow: 0px -10px 30px 10px #000000;
    transform: rotate(-0.5deg);
    z-index: 999; }
  .teaser article {
    position: absolute;
    top: 50%;
    left: 0%;
    width: 100vw;
    padding-left: 180px;
    padding-right: 180px; }
    .teaser article h1 {
      font-size: 90px;
      color: #ccc;
      font-weight: 600;
      text-align: left;
      border-bottom: 0px !important;
      font-family: "Roboto Condensed";
      mix-blend-mode: color-dodge;
      opacity: 0.5; }
    .teaser article p {
      font-size: 34px;
      color: #ccc;
      opacity: 1;
      text-align: left;
      font-family: 'Roboto Condensed';
      mix-blend-mode: color-dodge;
      opacity: 0.5; }
    .teaser article .embed-responsive {
      box-shadow: 0px 0px 15px #000;
      background: #878787;
      border-radius: 15px; }
    .teaser article .fade-in {
      opacity: 0; }
  .teaser .fade-rtl, .teaser .fade-ltr {
    transform: matrix(1, 0, 0, 1, 0, 0);
    transition: all, 0s, 0s;
    transition-duration: 3s, 3s;
    transition-property: transform, opacity;
    transition-timing-function: ease-in-out, linear;
    will-change: opacity, transform;
    opacity: 0; }
  .teaser .fade-rtl {
    transform: translate(50px); }
  .teaser .fade-ltr {
    transform: translate(-50px); }
  .teaser #conversionbuttonindex2 {
    position: absolute;
    right: 20%;
    bottom: 35%;
    opacity: 0;
    font-size: 35px;
    box-shadow: 0px 0px 15px #333;
    padding: 10px 20px 10px 20px;
    transition: all 0.5s ease; }

.teaser--shadow {
  width: 100%;
  height: 10px;
  background: transparent;
  transform: translateY(0px);
  z-index: 1001;
  position: relative;
  box-shadow: 0px -7px 10px rgba(0, 0, 0, 0.5) !important; }

.teaser--main {
  overflow: hidden;
  position: relative; }
  .teaser--main .rohr {
    background: linear-gradient(to right, #e3cdae 0%, #fff8ee 40%, #ffffff 45%, #fff8ee 50%, #aea598 80%);
    width: 25px;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 10%;
    z-index: 9999;
    transform: translateX(50%); }
  .teaser--main .logo-only {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99; }
    .teaser--main .logo-only picture img {
      width: 250px; }

@media (max-width: 991.98px) {
  .teaser--main .logo-only picture img {
    width: 125px; } }

.schalter {
  position: absolute;
  cursor: pointer;
  width: 150px;
  height: 0px;
  right: 10%;
  z-index: 999;
  transform: translate(50%, -10px); }
  .schalter img {
    width: 100%;
    z-index: 9999; }
  .schalter .schalter-clicked {
    width: 84px;
    top: 76px;
    right: 33px;
    position: absolute;
    display: none; }
    .schalter .schalter-clicked img {
      width: 100%; }
  .schalter .rohr {
    background: linear-gradient(to right, #e3cdae 0%, #fff8ee 40%, #ffffff 45%, #fff8ee 50%, #aea598 80%);
    width: 25px;
    height: 30px;
    position: absolute;
    top: 0px;
    right: 10%;
    z-index: 9999;
    transform: translate(50%);
    right: 50%; }

.teaser--halb .teaser {
  width: 100%;
  height: calc(100vh / 1.2);
  max-height: 400px;
  min-height: 300px;
  position: relative;
  overflow: hidden; }
  .teaser--halb .teaser .teaser--bg {
    width: 100%;
    height: 250%;
    position: absolute;
    opacity: 0;
    transition: opacity 0.6s; }
    .teaser--halb .teaser .teaser--bg img {
      height: auto; }
  .teaser--halb .teaser .active {
    opacity: 1; }
  .teaser--halb .teaser .teaser--shadow {
    position: absolute;
    bottom: -10px;
    width: 100vw;
    height: 0px;
    box-shadow: 0px -10px 10px #000000;
    transform: rotate(-0.5deg);
    z-index: 999; }
  .teaser--halb .teaser article {
    position: absolute;
    top: 30%;
    left: 0%;
    width: 100vw;
    padding-left: 180px;
    padding-right: 180px; }
    .teaser--halb .teaser article h1 {
      font-size: 90px;
      color: #ffffff;
      font-weight: 600;
      text-align: left;
      border-bottom: 0px !important;
      font-family: "Roboto Condensed"; }
    .teaser--halb .teaser article p {
      font-size: 25px;
      text-shadow: rgba(0, 0, 0, 0.9) 1px 1px 10px;
      color: #ffffff;
      opacity: 0;
      text-align: left;
      font-family: "Roboto"; }
    .teaser--halb .teaser article .embed-responsive {
      box-shadow: 0px 0px 15px #000;
      background: #878787;
      border-radius: 15px; }
    .teaser--halb .teaser article .fade-in {
      opacity: 0; }
  .teaser--halb .teaser .fade-rtl, .teaser--halb .teaser .fade-ltr {
    transform: matrix(1, 0, 0, 1, 0, 0);
    transition: all, 0s, 0s;
    transition-duration: 3s, 3s;
    transition-property: transform, opacity;
    transition-timing-function: ease-in-out, linear;
    will-change: opacity, transform;
    opacity: 0; }
  .teaser--halb .teaser .fade-rtl {
    transform: translate(50px); }
  .teaser--halb .teaser .fade-ltr {
    transform: translate(-50px); }
  .teaser--halb .teaser #conversionbuttonindex2 {
    position: absolute;
    right: 20%;
    bottom: 35%;
    opacity: 0;
    font-size: 35px;
    box-shadow: 0px 0px 15px #333;
    padding: 10px 20px 10px 20px;
    transition: all 0.5s ease; }

@media (max-width: 767.98px) {
  .teaser--halb .teaser {
    max-height: 110px;
    min-height: 240px; }
  .clock i svg, .clock i img {
    height: 30px !important;
    transform: translate(10px, -9px) !important; } }

.has-open, .has-closed {
  position: absolute;
  z-index: 999;
  height: auto;
  transform: translate(-50%, -80px);
  left: 50%;
  background: #ffffff;
  width: 580px;
  display: flex;
  height: 80px;
  font-family: 'Roboto Condensed'; }
  .has-open .clock, .has-closed .clock {
    width: 85px;
    height: 60px;
    margin-top: 10px;
    border-right: 1px solid #cdc0b0;
    margin-right: 15px; }
    .has-open .clock i, .has-closed .clock i {
      font-size: 60px;
      color: #cdc0b0; }
      .has-open .clock i svg, .has-open .clock i img, .has-closed .clock i svg, .has-closed .clock i img {
        height: 60px;
        transform: translate(10px, -15px); }
  .has-open .text, .has-closed .text {
    font-size: 50px;
    font-weight: bold;
    color: #301610;
    margin-top: 5px; }
  .has-open .text-closed, .has-closed .text-closed {
    font-size: 50px;
    font-weight: bold;
    color: #301610;
    margin-top: 5px; }
  .has-open .zeit, .has-closed .zeit {
    position: relative;
    width: 165px;
    margin-left: 20px;
    margin-top: 10px;
    font-weight: bold;
    font-size: 35px;
    color: #cdc0b0; }

.teaser--shadow {
  width: 100%;
  height: 10px;
  background: transparent;
  transform: translateY(0px);
  z-index: 1001;
  position: relative;
  box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.5) !important; }

.teaser--main {
  overflow: hidden;
  position: relative; }

.has-closed {
  width: 450px; }

.appartments--startseite {
  position: relative;
  width: 100%;
  margin-bottom: 50px; }
  .appartments--startseite .bilder--slider {
    width: 100%;
    border: 40px solid #fff;
    box-shadow: 0px 0px 15px #666;
    margin-top: 30px;
    padding-bottom: 56.25%; }
    .appartments--startseite .bilder--slider div {
      display: block;
      opacity: 0;
      transition: opacity 0.6s; }
    .appartments--startseite .bilder--slider .active {
      display: flex;
      opacity: 1; }
    .appartments--startseite .bilder--slider div picture {
      position: absolute;
      top: 40px;
      left: 40px;
      width: calc(100% - 80px);
      height: calc(100% - 80px);
      overflow: hidden; }
      .appartments--startseite .bilder--slider div picture img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    @media (max-width: 1199.98px) {
      .appartments--startseite .bilder--slider div picture {
        position: absolute;
        top: 20px;
        left: 20px;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        overflow: hidden; } }
  .appartments--startseite .bilder-slider-preview {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center; }
    .appartments--startseite .bilder-slider-preview .btn-primary {
      background: #d9b37d;
      color: #fff;
      border: 0px;
      font-size: 25px;
      font-weight: bold;
      margin-right: 10px; }
    .appartments--startseite .bilder-slider-preview .btn-secondary {
      background: #878787;
      color: #fff;
      font-size: 25px;
      width: 50px;
      height: 50px;
      font-weight: bold; }

.kontakticons {
  position: relative;
  display: flex;
  flex-direction: row;
  font-size: 70px;
  justify-content: space-around;
  margin-bottom: 30px;
  z-index: 10;
  max-width: 680px;
  left: 50%;
  transform: translateX(-50%); }
  .kontakticons a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    transition: color 0.3s; }
  .kontakticons a:hover {
    color: #ecd9be !important; }
  .kontakticons i {
    padding: 15px 30px 10px 30px;
    width: 130px;
    transform: translateX(-50%);
    position: relative;
    left: 50%; }
    .kontakticons i svg {
      height: 70px; }
    .kontakticons i img {
      height: 70px; }
  .kontakticons .text {
    font-size: 16px;
    text-align: center; }

.dunkle-icons a {
  color: #d9b37d !important; }

.dunkle-icons a:hover {
  color: #ecd9be !important; }

::selection {
  text-shadow: none;
  background: #ccc4ba; }

a {
  color: #878787; }

a:hover {
  color: #301610;
  text-decoration: none; }

h1 {
  margin-top: 35px;
  text-align: center;
  color: #878787;
  text-transform: uppercase;
  font-size: 70px;
  font-family: "Roboto Condensed";
  font-weight: 300;
  margin-bottom: 0px;
  text-shadow: 1px 1px 0px #ffffff; }

h2 {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #d9b37d;
  font-size: 32px;
  font-family: 'Roboto Condensed';
  font-weight: 600;
  text-shadow: 1px 1px 0px #ffffff; }

h3 {
  font-size: 1.7rem; }

html {
  text-rendering: optimizeLegibility;
  font-kerning: normal;
  font-feature-settings: "kern"; }

body {
  display: block;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  font-size: 20px;
  font-weight: 200;
  color: #302e2c; }
  body a {
    font-weight: 400;
    color: #302e2c;
    transition: color 0.3s; }
  body a:hover {
    color: #d9b37d; }
  body .iconlist {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center; }
    body .iconlist a {
      display: flex;
      cursor: default; }
      body .iconlist a .fontawsome-icon {
        width: 50px;
        height: 50px;
        background: #878787;
        border-radius: 0.25rem;
        margin: 5px;
        padding: 5px; }
        body .iconlist a .fontawsome-icon svg * {
          stroke: #ffffff; }
  body .appartment-item-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    body .appartment-item-list .appartment-item {
      display: flex;
      flex-direction: row;
      width: calc(100% / 3); }
      body .appartment-item-list .appartment-item .icon {
        margin-right: 10px; }
        body .appartment-item-list .appartment-item .icon a {
          display: flex; }
          body .appartment-item-list .appartment-item .icon a .fontawsome-icon {
            width: 40px;
            height: 40px;
            background: #878787;
            border-radius: 0.25rem;
            margin: 5px;
            padding: 5px; }
            body .appartment-item-list .appartment-item .icon a .fontawsome-icon svg * {
              stroke: #ffffff; }
      body .appartment-item-list .appartment-item .description p {
        margin-top: 10px; }
  body .container-color-1 {
    background: #d9b37d !important; }
    body .container-color-1 h1, body .container-color-1 h2, body .container-color-1 p {
      color: #ffffff !important; }
    body .container-color-1 h1, body .container-color-1 h2 {
      text-shadow: -0.5px -0.5px #000; }
    body .container-color-1 a {
      color: #ffffff; }
    body .container-color-1 a:hover {
      color: #ecd9be; }
  body .container-color-3 {
    background: #ffffff !important; }
  body .container-color-4 {
    background: transparent !important; }
  body .container-color-2 {
    background: #ffffff !important; }
    body .container-color-2 .background-repeat {
      position: absolute;
      background-image: url("../../../media/struktur-XL@1x/struktur.png");
      background-position: top;
      background-repeat: repeat-y;
      background-size: cover;
      width: 100%;
      height: calc(100% - 30px);
      opacity: 1;
      z-index: 1;
      top: 0px;
      left: 0px;
      zoom: 0.5; }
  body .logo-page {
    height: 120px;
    position: fixed;
    top: 15px;
    left: 15px;
    z-index: 9999; }
  body .header {
    max-height: 170px;
    height: 160px;
    overflow: hidden;
    width: 100vw;
    position: fixed;
    top: 0px;
    z-index: 50;
    background: #ffffff; }
    body .header .holz {
      position: absolute;
      bottom: 0px;
      height: 100px;
      border-top: 20px solid #fff5e9;
      width: 100vw; }
      body .header .holz img {
        width: 100%; }
  body .content {
    margin-top: 0px;
    position: relative; }
    body .content .grid-container:first-child {
      z-index: 999; }
    body .content .grid-container {
      z-index: 10;
      background: #fff5e9; }
      body .content .grid-container .pb-5 {
        padding-bottom: 10rem !important; }
      body .content .grid-container .container {
        position: relative;
        z-index: 3; }
      body .content .grid-container .content--shadow {
        z-index: 4; }
        body .content .grid-container .content--shadow picture {
          position: absolute;
          top: 0px; }
          body .content .grid-container .content--shadow picture img {
            width: 100vw;
            position: absolute; }
      body .content .grid-container .container-fluid .row .col-12 {
        margin: 0px;
        padding: 0px; }
  body .textblock {
    overflow-x: auto; }
    body .textblock h2 {
      padding-top: 20px; }
  body .hrh1 {
    background: #b5b5b5;
    border-bottom: 1px solid #fff;
    margin-top: -10px; }
  body header {
    display: flex;
    width: 100vw;
    position: fixed;
    z-index: 99; }
  body main {
    z-index: 50;
    flex: 1;
    width: 100%;
    padding-top: 126px;
    padding-bottom: 0px;
    min-height: calc(100vh - 164px); }
    body main .fa-ul {
      padding: 10px 0px; }
  body .content--shadow-footer {
    position: absolute;
    bottom: 0px;
    width: 100vw;
    transform: translate(0px, 0%); }
    body .content--shadow-footer picture {
      position: relative;
      width: 100%;
      left: 0px;
      top: 0px; }
      body .content--shadow-footer picture img {
        width: 100%;
        position: relative;
        transform: translateX(-50%);
        filter: brightness(1) !important;
        left: 50%;
        z-index: 999; }
  body footer {
    z-index: 60;
    background: transparent;
    padding: 0px;
    margin: 0px !important;
    position: relative;
    width: 100%;
    min-height: 50px; }
    body footer .logo-footer {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 999999;
      background: #fff;
      border-radius: 15px;
      padding: 15px;
      top: 0px;
      box-shadow: 0px 10px 15px #333; }
      body footer .logo-footer img {
        width: 225px; }
    @media (max-width: 1199.98px) {
      body footer .logo-footer img {
        width: 100px; } }
    body footer .texture {
      width: 100%;
      padding: 0px;
      position: relative;
      overflow: hidden;
      min-height: 120px; }
      body footer .texture .stoff {
        position: absolute;
        background: url("../../../media/stoff-XL@1x/stoff_texture.jpg");
        background-size: cover;
        width: 100%;
        height: 100%; }
        body footer .texture .stoff img {
          width: 100%;
          filter: brightness(0.7); }
      body footer .texture .grid-container {
        padding-top: 100px; }
        body footer .texture .grid-container h1, body footer .texture .grid-container h2, body footer .texture .grid-container p {
          color: #ffffff !important;
          text-shadow: -1px -1px 0px #000000; }
    body footer .bg-white {
      width: 100%;
      position: relative;
      text-align: center;
      padding-bottom: 1px;
      padding-top: 10px; }
      body footer .bg-white .grid-container .container-fluid {
        padding-top: 10px; }
      body footer .bg-white .links {
        font-size: 40px;
        font-family: "Roboto Condensed";
        font-weight: 600;
        color: #d9b37d; }
        body footer .bg-white .links a {
          color: #d9b37d; }
      @media (max-width: 1199.98px) {
        body footer .bg-white .links {
          font-size: 20px;
          padding-bottom: 30px; } }
      body footer .bg-white .copyright {
        font-family: "Roboto Condensed";
        font-size: 15px;
        font-weight: 400;
        color: #d9b37d; }
    body footer .content--shadow-footer, body footer .content--shadow {
      position: absolute;
      top: 0px;
      width: 100%; }
      body footer .content--shadow-footer picture, body footer .content--shadow picture {
        position: absolute;
        width: 100%;
        left: 0px;
        top: 0px; }
        body footer .content--shadow-footer picture img, body footer .content--shadow picture img {
          width: 100%;
          position: absolute;
          transform: translateX(-50%);
          filter: brightness(1) !important;
          left: 50%;
          z-index: 999; }
  body .btn-primary {
    color: #212529; }

.grid-container {
  position: relative; }
  .grid-container .content--shadow {
    position: absolute;
    top: 0px;
    width: 100%; }
  .grid-container .container-fluid-shadow {
    overflow: hidden;
    position: relative;
    z-index: 3; }

.ani-fade-in {
  transform: translate(0px, 0px) !important;
  opacity: 1 !important; }

.socialmediaicons {
  font-size: 25px; }

.dropdown-item.active, .dropdown-item:active {
  background-color: transparent !important; }

.bildmittext-box {
  margin-bottom: 40px;
  margin-top: 20px; }
  .bildmittext-box img {
    max-width: 100%; }

.buttons-modul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px; }
  .buttons-modul a {
    justify-content: center;
    text-align: center;
    transition: color 0.3s; }
  .buttons-modul .btn-modul {
    display: flex;
    justify-content: center; }
    .buttons-modul .btn-modul i {
      font-size: 60px;
      padding: 10px;
      border-radius: 10px;
      background: #878787;
      color: #fff;
      margin-bottom: 10px;
      transition: color 0.3s;
      width: 80px;
      height: 80px; }
      .buttons-modul .btn-modul i img, .buttons-modul .btn-modul i svg {
        width: 60px;
        max-height: 60px;
        max-width: 60px;
        transform: translateY(-19px); }
  .buttons-modul a:hover i {
    color: rgba(255, 255, 255, 0.5); }

.modal {
  z-index: 999999;
  display: block; }
  .modal .modal-dialog {
    transform: translateY(-50%);
    max-width: 800px; }
    .modal .modal-dialog .modal-header {
      padding-bottom: 0px;
      padding-top: 0px;
      border: none; }
      .modal .modal-dialog .modal-header .close {
        background: none;
        border: none; }
    .modal .modal-dialog .modal-body {
      max-height: calc(100vh - 240px);
      overflow-y: scroll; }

.jetzt-buchen {
  position: relative;
  border-radius: 10px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  z-index: 99; }
  .jetzt-buchen .btn {
    position: relative;
    font-size: 32px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0.5);
    left: 50%;
    transform: translateX(-50%);
    color: #d9b37d;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.33);
    border: 0px;
    border-radius: 10px;
    padding: 0px 30px;
    text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.33);
    transition: all 0.3s; }
  .jetzt-buchen .btn:hover {
    background-color: white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.33); }

.bildergalerie--article {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1; }
  .bildergalerie--article .teaser--bg {
    width: 100%;
    height: 100%;
    position: fixed;
    opacity: 0;
    transition: opacity 0.6s;
    display: flex;
    object-fit: cover; }
    .bildergalerie--article .teaser--bg picture {
      height: 100%;
      width: 100%;
      object-fit: cover; }
      .bildergalerie--article .teaser--bg picture img {
        height: 100%;
        width: 100%;
        object-fit: cover; }
  .bildergalerie--article .active {
    opacity: 1; }

.appartments--boxen {
  margin-bottom: 50px; }
  .appartments--boxen .card {
    border-radius: 0px 0px 15px 15px;
    box-shadow: 0px 10px 20px -10px #000000;
    margin-bottom: 40px; }
    .appartments--boxen .card .card-header {
      font-size: 25px;
      font-weight: 600;
      background: #ffffff; }
      .appartments--boxen .card .card-header .topprice {
        width: 100%;
        text-align: center;
        color: #878787;
        font-size: 70px;
        font-weight: 300;
        text-shadow: 1px 1px 0px #ffffff; }
      .appartments--boxen .card .card-header .quadratmeter {
        text-align: center;
        width: 100%;
        font-size: 32px;
        font-eight: 600;
        color: #d9b37d; }
    .appartments--boxen .card .card-body {
      background: #d9b37d;
      border-radius: 0px 0px 15px 15px; }
      .appartments--boxen .card .card-body .lowest {
        position: absolute;
        right: 20px;
        top: 20px;
        transform: rotate(35deg) !important;
        font-size: 20px;
        font-weight: 600; }
      .appartments--boxen .card .card-body .devider {
        width: 100%;
        background: #ffffff;
        margin: 20px 0px;
        height: 1px; }
      .appartments--boxen .card .card-body .jetzt-buchen {
        position: relative;
        margin-top: 10px;
        display: flex;
        width: 100%;
        background: white; }
        .appartments--boxen .card .card-body .jetzt-buchen .btn {
          font-size: 32px;
          font-weight: 600;
          width: 100%; }
        .appartments--boxen .card .card-body .jetzt-buchen .btn:hover {
          background: rgba(255, 255, 255, 0.5); }
    .appartments--boxen .card .pricelist {
      padding-left: 0px;
      margin-bottom: 0px;
      min-height: 120px; }
      .appartments--boxen .card .pricelist li {
        margin: 0px;
        color: #fff; }
        .appartments--boxen .card .pricelist li .preis {
          text-align: right; }

.videooverlayeffekt {
  max-width: calc(100vh / 9 * 16 - 100px) !important;
  max-height: calc(100vw / 16 * 9) !important; }

#videooverlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw - 50px);
  box-shadow: 0px 0px 50px #000;
  border-radius: 15px;
  max-width: 50px;
  opacity: 1;
  transition: max-width 1.5s;
  z-index: 999999999;
  overflow: hidden;
  background: #f7f9ff; }
  #videooverlay #closevideoverlay {
    position: fixed;
    width: 50px;
    height: 50px;
    border-radius: 0px 15px 0px 0px;
    top: 0px;
    right: 0px;
    background: rgba(255, 255, 255, 0.75);
    color: #000;
    z-index: 999999;
    font-size: 40px;
    font-weight: bold;
    padding: 0px 14px; }
  #videooverlay #closevideoverlay::before {
    content: 'X'; }

@media (max-width: 991.98px) {
  .videooverlayeffekt {
    max-width: calc(100vh / 9 * 16 - 50px) !important;
    max-height: calc(100vw / 16 * 9) !important; }
  #videooverlay {
    border-radius: 5px; }
  #closevideoverlay {
    border-radius: 0px 5px 0px 0px !important;
    width: 40px !important;
    height: 40px !important;
    font-size: 30px !important;
    padding: 0px 12px !important; } }

body .navbar {
  transition: background-color 0.8s; }

body h2 {
  transition: color 0.8s; }

.clicked .navbar {
  background-color: #6b5f4e; }

.clicked h2 {
  color: #ffffff; }

.clicked .content .grid-container {
  background: #6b5f4e;
  color: #ffffff; }

@media (max-width: 1199.98px) {
  .news .col-xl-6 .entry-content {
    margin-top: -10px; } }

@media (max-width: 991.98px) {
  .navbar {
    top: 80px;
    box-shadow: 0 -10px 20px 10px #000;
    height: auto; }
    .navbar .navbar-toggler {
      border: none;
      box-shadow: none; }
    .navbar .navbar-nav {
      height: auto;
      font-size: 25px; }
      .navbar .navbar-nav .nav-item {
        min-height: 35px;
        flex: unset;
        flex-direction: column; }
        .navbar .navbar-nav .nav-item a {
          width: 100vw;
          text-align: center;
          justify-content: center; }
        .navbar .navbar-nav .nav-item .dropdown-menu a {
          font-size: 20px; }
    .navbar .navbar-collapse {
      position: fixed;
      top: 80px;
      width: 100vw; }
    .navbar .nextdate {
      display: none; }
  body .content {
    margin-top: 80px; }
  body .teaser article {
    top: 20%;
    padding-left: 20px;
    padding-right: 20px; }
  body .bilder-slider-preview {
    max-width: 100vw; }
  footer .wood .footer--menu {
    flex-direction: column !important;
    display: none !important; }
  footer .wood .logo-footer {
    width: 50%;
    left: 50%;
    position: relative;
    transform: translateX(-50%); }
  footer .wood .socialmediaicons-footer i {
    font-size: 35px; } }

@media (max-width: 1199.98px) {
  .navbar {
    top: 0px;
    box-shadow: 0 -10px 20px 10px #000;
    height: 70px; }
    .navbar .pr-md-5 {
      padding-right: 0px !important; }
    .navbar .logo-page {
      height: 60px;
      top: 5px;
      left: 5px; }
    .navbar .navbar-toggler {
      border: none;
      box-shadow: none; }
    .navbar .navbar-nav {
      height: auto;
      font-size: 18px;
      flex-direction: row;
      width: 100vw !important;
      padding-left: 210px;
      padding-right: 0px; }
      .navbar .navbar-nav .nav-item {
        min-height: 35px;
        flex: unset;
        flex-direction: column;
        padding-right: 15px;
        margin-top: 5px; }
        .navbar .navbar-nav .nav-item a {
          text-align: center;
          justify-content: center;
          font-size: 30px; }
        .navbar .navbar-nav .nav-item .dropdown-menu a {
          font-size: 20px; }
    .navbar .navbar-collapse {
      position: fixed;
      top: 0px;
      width: 100vw; }
    .navbar .nextdate {
      display: none; }
  body .content {
    margin-top: 0px; }
  body .rohr {
    display: none; }
  body .schalter {
    display: none; }
  body .teaser--main {
    height: 100vh;
    object-fit: cover; }
    body .teaser--main .teaser {
      height: 100%;
      width: 100%;
      object-fit: cover; }
      body .teaser--main .teaser div {
        object-fit: cover;
        height: 100%;
        width: 100%; }
        body .teaser--main .teaser div video {
          object-fit: cover; }
    body .teaser--main article {
      top: 20%;
      padding-left: 20px;
      padding-right: 20px; }
  body .bilder-slider-preview {
    max-width: 100vw; }
  footer .wood .footer--menu {
    flex-direction: column !important;
    display: none !important; }
  footer .wood .logo-footer {
    width: 50%;
    left: 50%;
    position: relative;
    transform: translateX(-50%); }
  footer .wood .socialmediaicons-footer i {
    font-size: 35px; } }

@media (max-width: 1199.98px) {
  body .logo-brauhaus picture img {
    max-height: 90px; }
  body .header {
    bottom: unset;
    top: 0px !important;
    max-height: 80px !important; }
  body .navbar .navbar-nav {
    flex-direction: column; }
  body .navbar .navbar-collapse {
    background: #d9b37d;
    left: 0px;
    top: 50px !important;
    box-shadow: 0 7px 7px -7px #000000; }
    body .navbar .navbar-collapse .navbar-nav {
      margin-left: 0px;
      padding-left: 0px; }
  body .navbar .socialmediaicons {
    position: fixed;
    top: 27px !important; }
    body .navbar .socialmediaicons i {
      color: #fff !important; }
  body .navbar .navbar-toggler {
    top: 15px !important;
    border-color: #fff; }
  body .navbar-light .navbar-toggler {
    color: #fff !important; }
  body .navbar-light .navbar-toggler-icon {
    background: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
  body .bilder--galerie .bilder--slider {
    height: calc(100vw / 16 * 8); } }

@media (max-width: 767.98px) {
  body .geschenkkarte {
    width: 100% !important;
    margin-left: 0px !important; }
  body .logo-brauhaus picture img {
    max-height: 80px; }
  body .navbar .navbar-toggler {
    transform: translateY(-10px); }
  body .navbar .header .holz .nextdate {
    font-size: 16px;
    opacity: 0;
    margin-left: 10px; }
  body .navbar .socialmediaicons {
    opacity: 0;
    width: calc(100vw - 70px);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    top: 7px !important; }
    body .navbar .socialmediaicons a i {
      margin: 0px 10px 10px 0px; }
      body .navbar .socialmediaicons a i svg, body .navbar .socialmediaicons a i img {
        height: 35px; }
  body .teaser--main {
    height: 350px !important; }
    body .teaser--main .teaser {
      margin-top: 0px; }
    body .teaser--main .jetzt-buchen .btn {
      width: 270px; }
    body .teaser--main .teaser--bg {
      height: 110% !important; }
      body .teaser--main .teaser--bg article {
        padding-left: 10px;
        padding-right: 10px;
        top: 40%; }
        body .teaser--main .teaser--bg article h1 {
          font-size: 40px;
          margin-top: 0px; }
        body .teaser--main .teaser--bg article p {
          font-size: 20px;
          font-weight: 600; }
    body .teaser--main .has-closed .clock i svg, body .teaser--main .has-closed .clock i img {
      height: 40px;
      transform: translate(5px, -9px) !important; }
  body .teaser--shadow {
    box-shadow: 0px -3px 7px rgba(0, 0, 0, 0.5) !important; }
  body .kalendar .monat .container-fluid > div {
    margin-bottom: 40px !important; }
  body .kontakticons a {
    height: 50px; }
    body .kontakticons a i {
      padding: 0px;
      width: 60px;
      display: flex;
      justify-content: center; }
      body .kontakticons a i img, body .kontakticons a i svg {
        height: 50px; }
    body .kontakticons a .text {
      display: none; }
  body footer .kontakticons {
    margin-bottom: 100px !important; }
    body footer .kontakticons a {
      height: 50px; }
      body footer .kontakticons a i {
        padding: 0px;
        width: 60px;
        display: flex;
        justify-content: center; }
        body footer .kontakticons a i img, body footer .kontakticons a i svg {
          height: 50px; }
      body footer .kontakticons a .text {
        display: none; }
  body footer .wood .hopfen picture img {
    max-width: 270px;
    width: 270px; }
  body .kartenkategorie {
    max-height: 30px;
    overflow: hidden;
    transition: max-height 0.8s; }
    body .kartenkategorie .karten-childrens article {
      margin: 0px;
      padding: 0px; } }

@media (max-width: 575.98px) {
  body .logo-brauhaus picture img {
    max-height: 70px; }
  body .bilder-slider-preview {
    bottom: 0px !important;
    height: 43px !important; }
    body .bilder-slider-preview picture {
      height: 40px; }
      body .bilder-slider-preview picture img {
        border: 1px solid #fff !important; }
  body h1 {
    font-size: 40px; }
  body h2 {
    font-size: 25px; }
  body .navbar {
    top: 0px;
    box-shadow: 0 -10px 20px 10px #000;
    height: 50px; }
    body .navbar .logo-page {
      height: 40px; }
    body .navbar .navbar-toggler {
      border: none;
      box-shadow: none; }
    body .navbar .navbar-nav {
      height: auto;
      font-size: 25px; }
      body .navbar .navbar-nav .nav-item {
        min-height: 35px;
        flex: unset;
        flex-direction: column; }
        body .navbar .navbar-nav .nav-item a {
          width: 100vw;
          text-align: center;
          justify-content: center; }
        body .navbar .navbar-nav .nav-item .dropdown-menu a {
          font-size: 20px; }
    body .navbar .navbar-collapse {
      position: fixed;
      top: 80px;
      width: 100vw; }
    body .navbar .nextdate {
      display: none; }
  body .content {
    margin-top: 0px; }
  body .has-open {
    width: 280px;
    height: 50px;
    transform: translate(-50%, -50px); }
    body .has-open .clock {
      width: 50px;
      height: 30px; }
      body .has-open .clock i {
        font-size: 30px; }
    body .has-open .text {
      font-size: 20px;
      margin-top: 11px; }
    body .has-open .zeit {
      font-size: 16px;
      width: 80px;
      margin-top: 11px; }
  body .has-closed {
    width: 210px;
    height: 50px;
    transform: translate(-50%, -50px); }
    body .has-closed .clock {
      width: 50px;
      height: 30px; }
      body .has-closed .clock i {
        font-size: 30px; }
    body .has-closed .text-closed {
      font-size: 20px;
      margin-top: 11px; }
    body .has-closed .zeit {
      font-size: 16px;
      width: 80px;
      margin-top: 11px; }
  .tages-special h2 {
    font-size: 30px !important; }
  .tages-special h3 {
    font-size: 25px !important; }
  .tages-special h4 {
    font-size: 85px !important; }
  .tages-special .picture {
    margin-top: 70px !important; }
  footer .wood .footer--menu {
    flex-direction: column !important;
    display: none !important; }
  footer .wood .logo-footer {
    width: 50%;
    left: 50%;
    position: relative;
    transform: translateX(-50%); }
  footer .wood .socialmediaicons-footer i {
    font-size: 35px; } }
