@include media-breakpoint-down(lg) {
  .navbar {
    top: 0px;
    box-shadow: 0 -10px 20px 10px #000;
    height: 70px;

    .pr-md-5 {
      padding-right: 0px !important;
    }

    .logo-page {
      height: 60px;
      top: 5px;
      left: 5px;
    }

    .navbar-toggler {
      border: none;
      box-shadow: none;
    }

    .navbar-nav {
      height: auto;
      font-size: 18px;
      flex-direction: row;
      width: 100vw !important;
      padding-left: 210px;
      padding-right: 0px;
      .nav-item {
        min-height: 35px;
        flex: unset;
        flex-direction: column;
        padding-right: 15px;
        margin-top: 5px;
        a {
          text-align: center;
          justify-content: center;
          font-size: 30px;
        }
        .dropdown-menu {
          a {
            font-size: 20px;
          }
        }
      }
    }
    .navbar-collapse {
      position: fixed;
      top: 0px;
      width: 100vw;
    }
    .nextdate {
      display: none;
    }
  }
  body {
    .content {
      margin-top: 0px;

    }
    .rohr {
      display: none;
    }
    .schalter {
      display: none;
    }
    .teaser--main {
      height: 100vh;
      object-fit: cover;
      .teaser {
        height: 100%;
        width: 100%;
        object-fit: cover;
        div {
          object-fit: cover;
          height: 100%;
          width: 100%;
          video {
            object-fit: cover;
          }
        }
      }
      article {
        top: 20%;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    .bilder-slider-preview {
      max-width: 100vw;
    }
  }
  footer {
    .wood {
      .holz {
        img {

        }
      }
      .footer--menu {
        flex-direction: column !important;
        display: none !important;
      }
      .logo-footer {
        width: 50%;
        left: 50%;
        position: relative;
        transform: translateX(-50%);
      }

      .socialmediaicons-footer {
        i {
          font-size: 35px;
        }
      }
    }
  }
}