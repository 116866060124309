.appartments--startseite {
  position: relative;
  width: 100%;
  margin-bottom: 50px;
  .bilder--slider {
    width: 100%;
    border: 40px solid #fff;
    box-shadow: 0px 0px 15px #666;
    margin-top: 30px;
    padding-bottom: 56.25%;
    div {
      display: block;
      opacity: 0;
      transition: opacity 0.6s;
    }
    .active {
      display: flex;
      opacity: 1;
    }
    div {
      picture {
        position: absolute;
        top: 40px;
        left: 40px;
        width: calc(100% - 80px);
        height: calc(100% - 80px);
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      @include media-breakpoint-down(lg) {
        picture {
          position: absolute;
          top: 20px;
          left: 20px;
          width: calc(100% - 40px);
          height: calc(100% - 40px);
          overflow: hidden;
        }
      }
    }
  }
  .bilder-slider-preview {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .btn-primary {
      background: #d9b37d;
      color: #fff;
      border: 0px;
      font-size: 25px;
      font-weight: bold;
      margin-right: 10px;
    }
    .btn-secondary {
      background: #878787;
      color: #fff;
      font-size: 25px;
      width: 50px;
      height: 50px;
      font-weight: bold;
    }
  }
}